'use client';

import { Box, Img } from '@chakra-ui/react';
import React from 'react';
import { HuutokaupatImage } from '@/components/Image/HuutokaupatImage';

interface CompanyBannerProps {
  banner?: {
    src: string;
    alt: string;
  };
  logo?: {
    src: string;
    alt: string;
  };
}

export function CompanyBanner({ banner, logo }: CompanyBannerProps) {
  const logoSize = { base: 80, md: 120 };

  const resolveBottomMargin = () => {
    if (banner !== undefined) {
      if (logo !== undefined) {
        // Logo and banner
        return {
          base: `calc(${logoSize.base / 2}px + var(--chakra-space-3))`,
          md: `calc(${logoSize.md / 2}px + var(--chakra-space-3))`,
        };
      }

      // Banner only
      return 4;
    }

    if (logo !== undefined) {
      // Logo only
      return 3;
    }

    return 0;
  };

  return (
    <Box
      position="relative"
      mx={banner !== undefined ? { base: -3, md: 0 } : undefined}
      mt={{ base: 0, md: 5 }}
      mb={resolveBottomMargin()}
    >
      {/* Unfortunately can't use next/image for the banner due to design requirements for size and scaling. */}
      {banner !== undefined && (
        <Img
          src={banner.src}
          alt={banner.alt}
          w="100%"
          maxH={450}
          aspectRatio={1720 / 450}
          objectFit="cover"
          verticalAlign="bottom"
        />
      )}
      {logo !== undefined && (
        <Box
          position={banner !== undefined ? 'absolute' : 'relative'}
          left={banner !== undefined ? 3 : undefined}
          bottom={banner !== undefined ? { base: `-${logoSize.base / 2}px`, md: `-${logoSize.md / 2}px` } : undefined}
          w={{ base: `${logoSize.base}px`, md: `${logoSize.md}px` }}
          h={{ base: `${logoSize.base}px`, md: `${logoSize.md}px` }}
          bg="white"
          borderWidth={4}
          borderColor="white"
          zIndex={10}
        >
          <HuutokaupatImage src={logo.src} alt={logo.alt} fill style={{ objectFit: 'contain' }} />
        </Box>
      )}
    </Box>
  );
}
